const Footer = () => {
    return (
        <>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-md-6 footer-info">
                                <h3>SMD</h3>
                                <p>Vi leverer relevant salgs og marketingdata om danske virksomheder. Kunne du tænke dig
                                    at høre mere så kontakt os på telefon 38 340 341 </p>
                            </div>

                            <div className="col-lg-2 col-md-6 footer-links">
                                <h4>Indhold på siden:</h4>
                                <ul>
                                    <li><a href="src/components/Footer#">Home</a></li>
                                    <li><a href="src/components/Footer#">Om SMD</a></li>
                                    <li><a href="src/components/Footer#">Priser</a></li>
                                    <li><a href="src/components/Footer#">Terms of service</a></li>
                                    <li><a href="src/components/Footer#">Privacy policy</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-contact">
                                <h4>Kontakt os</h4>
                                <p>
                                    Fruebjergvej 3 <br/>
                                    2100 København Ø <br/>
                                    <br/>
                                        <strong>Telefon:</strong> +45 38 340 341<br/>
                                        <strong>Email:</strong> hs@sabroe.dk<br/>
                                </p>

                                <div className="social-links">
                                    <a href="src/components/Footer#" className="twitter"><i className="bi bi-twitter"></i></a>
                                    <a href="src/components/Footer#" className="facebook"><i className="bi bi-facebook"></i></a>
                                    <a href="src/components/Footer#" className="instagram"><i className="bi bi-instagram"></i></a>
                                    <a href="src/components/Footer#" className="instagram"><i className="bi bi-instagram"></i></a>
                                    <a href="src/components/Footer#" className="linkedin"><i className="bi bi-linkedin"></i></a>
                                </div>

                            </div>

                            <div className="col-lg-3 col-md-6 footer-newsletter">
                                <h4>Kursus i mødebooking og opsøgende salg</h4>
                                <p>Kunne du tænke dig at komme med på et af vores gratis kurser - så registrer dig her.
                                    vi sender løbende information om kommende kurser samt inspirerende information om
                                    hvordan du lander din næste kunde. </p>
                                <form action="src/components/Footer" method="post">
                                    <input type="email" name="email"/><input type="submit" value="Tilmeld dig her"/>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong>SMD</strong>. All Rights Reserved
                    </div>

                </div>
            </footer>
        </>
    )
}

export default Footer;