const WhyUs = () => {
    return (
        <>
            <section id="why-us">
                <div className="container" data-aos="fade-up">
                    <header className="section-header">
                        <h3>Det får du!</h3>
                        <p>Vi har gjort os en stor umage for at fjerne tomme cvr. numre - det vil sige cvr. numre der
                            ikke repræsentere en reel virksomhed</p>
                    </header>

                    <div className="row row-eq-height justify-content-center">

                        <div className="col-lg-4 mb-4">
                            <div className="card" data-aos="zoom-in" data-aos-delay="100">
                                <i className="bi bi-building-fill"></i>
                                <div className="card-body">
                                    <h5 className="card-title">315.000 virksomheder</h5>
                                    <p className="card-text">Vi har gjort os en stor umage for at fjerne virksoheder som
                                        ikke er interessante set ud fra et salgspersektiv. Vi prøver såvidt muligt at
                                        fjerne virksomheder uden ansatte og uden en online tilstedeværelse.</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 mb-4">
                            <div className="card" data-aos="zoom-in" data-aos-delay="200">
                                <i className="bi bi-person-vcard-fill"></i>
                                <div className="card-body">
                                    <h5 className="card-title">450.000 kontaktpersoner</h5>
                                    <p className="card-text">Vi gør alt hvad vi kan for at finde kontaktdata på
                                        medarbejderene i din målgruppe. alt sker naturligvis inden for rammerne af
                                        GDPR-reglerne.</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 mb-4">
                            <div className="card" data-aos="zoom-in" data-aos-delay="300">
                                <i className="bi bi-diagram-3-fill"></i>
                                <div className="card-body">
                                    <h5 className="card-title">489 kædekontorer brands</h5>
                                    <p className="card-text">Vi har samlet kontaktinformationer på en lang række danske
                                        kæder og brands. </p>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row counters" data-aos="fade-up" data-aos-delay="100">

                        <div className="col-lg-3 col-6 text-center">
                            <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1"
                                  className="purecounter"></span>
                            <p>Virksomheder</p>
                        </div>

                        <div className="col-lg-3 col-6 text-center">
                            <span data-purecounter-start="0" data-purecounter-end="421" data-purecounter-duration="1"
                                  className="purecounter"></span>
                            <p>Kontaktpersoner</p>
                        </div>

                        <div className="col-lg-3 col-6 text-center">
                            <span data-purecounter-start="0" data-purecounter-end="1364" data-purecounter-duration="1"
                                  className="purecounter"></span>
                            <p>kædekontorer</p>
                        </div>

                        <div className="col-lg-3 col-6 text-center">
                            <span data-purecounter-start="0" data-purecounter-end="42" data-purecounter-duration="1"
                                  className="purecounter"></span>
                            <p>Brands</p>
                        </div>

                    </div>

                </div>
            </section>

        </>
    )
}

export default WhyUs