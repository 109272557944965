import "./assets/css/bootstrap.min.css"
import "./assets/css/style.css"
import "./assets/css/bootstrap-icons.css"
import Home from "./components/Home"

function App() {
  return (
    <div className="App">
      <Home/>
    </div>
  );
}

export default App;
