const Contact = () => {
    return (
        <>
            <section id="contact">
                <div className="container-fluid" data-aos="fade-up">

                    <div className="section-header">
                        <h3>Kontakt os</h3>
                    </div>

                    <div className="row">

                        <div className="col-lg-6">
                            <div className="map mb-4 mb-lg-0">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2247.4606646435695!2d12.54679207733732!3d55.71574607307044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465252424df4ee99%3A0x18a45849a63c4e1e!2sFruebjergvej%203%2C%202100%20K%C3%B8benhavn%20%C3%98!5e0!3m2!1sda!2sdk!4v1721047335671!5m2!1sda!2sdk"
                                    frameBorder="0" style={{border: "0", width: "100%", height: "340px"}}
                                    allowFullScreen></iframe>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-md-5 info">
                                    <i className="bi bi-geo-alt"></i>
                                    <p>Fruebjergvej 3, 2100 København Ø</p>
                                </div>
                                <div className="col-md-4 info">
                                    <i className="bi bi-envelope"></i>
                                    <p>hs@sabroe.dk</p>
                                </div>
                                <div className="col-md-3 info">
                                    <i className="bi bi-phone"></i>
                                    <p>+45 38 340 341</p>
                                </div>
                            </div>

                            <div className="form">
                                <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <input type="text" name="name" className="form-control" id="name"
                                                   placeholder="Dit navn" required/>
                                        </div>
                                        <div className="form-group col-lg-6 mt-3 mt-lg-0">
                                            <input type="email" className="form-control" name="email" id="email"
                                                   placeholder="Din Email" required/>
                                        </div>
                                    </div>
                                    <div className="form-group mt-3">
                                        <input type="text" className="form-control" name="subject" id="subject"
                                               placeholder="Vedr." required/>
                                    </div>
                                    <div className="form-group mt-3">
                                        <textarea className="form-control" name="message" rows="5" placeholder="Besked"
                                                  required></textarea>
                                    </div>
                                    <div className="my-3">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Din beksked er sendt. Tak!</div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" title="Send Message">Send meddelse</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}

export default Contact