import React, { useRef, useState } from 'react';
import Footer from "./Footer";
import WhyUs from "./WhyUs";
import Contact from "./Contact";
import Logo from "../assets/img/logo.png";
import AboutImage from "../assets/img/about-img.svg"
import AboutImage1 from "../assets/img/about-extra-1.svg"
import AboutImage2 from "../assets/img/about-extra-2.svg"
import HeroImage from "../assets/img/hero-img.svg";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css'
import {toast} from 'react-toastify'

toast.configure();

const user_state = {
    company_name: '',
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    payment_plan_id: 1,
    address: ''
}

const Home = () => {

    const [modalOpenClass, setModalOpenClass] = useState("none")
    const aboutSection = useRef(null);
    const serviceSection = useRef(null);
    const demoSection = useRef(null);
    const createUserSection = useRef(null);
    const [company, setCompany] = useState(null)
    const [showModal, setShowModal] = useState("none")
    const [companies, setCompanies] = useState([])
    const [user, setUser] = useState(user_state)

    const handleAboutSection = () => {
        aboutSection.current.scrollIntoView({ behavior: 'smooth' });
    }

    const handleServiceSection = () => {
        serviceSection.current.scrollIntoView({ behavior: 'smooth' });
    }

    const handleDemoSection = () => {
        demoSection.current.scrollIntoView({ behavior: 'smooth' });
    }

    const handleCreateUser = (e) => {
        e.preventDefault()

        axios.post('users', {user: user }, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }})
            .then(response => {
                setUser(user_state)
                toast.success("User created successfully!", {position: toast.POSITION.TOP_RIGHT})
            }).catch(error => {

                if(error.response.status === 400){
                    toast.error(error.response.data.errors.join(", "), {position: toast.POSITION.TOP_RIGHT})
                }
                console.log()
        })

    }

    const handleCreateUserSection = () => {
        createUserSection.current.scrollIntoView({ behavior: 'smooth' });
    }

    const handleLoginPage = () => {
        window.open("http://app.smd.dk/users/sign_in",'_blank');
    }

    const handleOpenCompanyData = (company) => {
        setShowModal("block")
        document.getElementById("input-element-search").value = ""
        setCompany(company)
        setCompanies([])
        setModalOpenClass("modal-backdrop fade show")
    }

    const handleCloseCompanyData = () => {
        setShowModal("none")
        setCompany(null)
        document.getElementById("input-element-search").value = ""
        setCompanies([])
        setModalOpenClass("none")
    }

    const handleSearchText = (text) => {
        if(text === "" || text === null){
            setCompanies([])
        }
        else{
            if(text.length >= 3) {
                axios.get('companies?search=' + text, {headers: {'Content-Type': 'application/json', Accept: "*/*"}})
                    .then(response => {
                        setCompanies(response.data.companies)
                    })
            }
        }

    }

    return (<>

        <header id="header" className="fixed-top d-flex align-items-center">
            <div className="container d-flex justify-content-between">

                <div className="logo">
                    <a href="/"><img src={Logo} alt="" className="img-fluid"/></a>
                </div>

                <nav id="navbar" className="navbar">
                    <ul>
                        <li><a className="nav-link scrollto active fs-4" href="/">Home</a></li>
                        <li><a className="nav-link scrollto fs-4" onClick={handleAboutSection} style={{ cursor: "pointer" }} >About</a></li>
                        <li><a className=" nav-link scrollto fs-4" onClick={handleServiceSection} style={{ cursor: "pointer" }}>Priser</a></li>
                        <li><a className="nav-link scrollto fs-4" onClick={handleDemoSection} style={{ cursor: "pointer" }}>Request Demo</a></li>
                        <li><a className="nav-link scrollto fs-4" onClick={handleCreateUserSection} style={{ cursor: "pointer" }}>Create user<i
                            className="bi bi-arrow-right fs-4"></i></a></li>

                        <button type="button" onClick={handleLoginPage} className="btn btn-outline-primary fs-4 me-auto">LogIn</button>



                    </ul>
                    <i className="bi bi-list mobile-nav-toggle"></i>
                </nav>


            </div>
        </header>


        <section id="hero" className="clearfix">
            <div className="container" data-aos="fade-up">

                <div className="hero-img" data-aos="zoom-out" data-aos-delay="200">
                    <img src={HeroImage} alt="" className="img-fluid"/>
                </div>

                <div className="hero-info" data-aos="zoom-in" data-aos-delay="100">
                    <h2>Mangler du<br/><span>kontaktinformationer</span><br/>på dine nye kunder</h2>
                    <div>
                        <a onClick={handleCreateUserSection} style={{ cursor: "pointer" }} className="btn-get-started scrollto">Create a user</a>
                        <a style={{cursor: "pointer"}}  onClick={handleServiceSection} className="btn-services scrollto">Priser</a>
                    </div>
                </div>

            </div>
        </section>




        <main id="main">

            <section id="about" ref={aboutSection}>
                <div className="container" data-aos="fade-up">


                    <div className="container center-container text-center">
                        <div>
                            <h2>Search for company. input company name/ cvr. nr. or phone nr.</h2>
                            <div className="form-group text-center">
                                <input
                                    onKeyUp={event => { handleSearchText(event.target.value) }}
                                    className="form-control form-width-60 mt-3  center input-lg" id="input-element-search"
                                       type="text"/>

                                <div className="dropdown-menu" style={{ width: "88.4%", display: (companies.length > 0 ? 'block' : 'none') }}>
                                    { companies.map( comp => <button onClick={event => {  handleOpenCompanyData(comp) }} className="dropdown-item">{comp.name}</button>)}
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="row about-container fw-bolder">
                        <div className="row">
                            <div className="col ">

                            </div>
                            <div className="col W-60">

                            </div>
                        </div>
                    </div>


                    <div className="row about-container">

                        <div className="col-lg-6 content order-lg-1 order-2 mt-4">
                            <p>
                                Kontakt informationer på

                            </p>

                            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                                <div className="icon"><i className="bi bi-card-checklist"></i></div>
                                <h4 className="title"><a href="src/components/About">Relevante danske virksomheder</a></h4>
                                <p className="description">Med ansatte eller omsætning.</p>
                            </div>

                            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                                <div className="icon"><i className="bi bi-brightness-high"></i></div>
                                <h4 className="title"><a href="src/components/About">Kontaktpersoner</a></h4>
                                <p className="description">Vi indsamller løbende offentlig tilgængelige kontaktdata</p>
                            </div>

                            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                                <div className="icon"><i className="bi bi-calendar4-week"></i></div>
                                <h4 className="title"><a href="src/components/About">Telefon og email</a></h4>
                                <p className="description">Få adgang til relevante kontaktinformationer</p>
                            </div>

                        </div>

                        <div className="col-lg-6 background order-lg-2" data-aos="zoom-in">
                            <img src={AboutImage} className="img-fluid" alt=""/>
                        </div>
                    </div>

                    <div className="row about-extra">
                        <div className="col-lg-6" data-aos="fade-right">
                            <img src={AboutImage1} className="img-fluid" alt=""/>
                        </div>
                        <div className="col-lg-6 pt-5 pt-lg-0" data-aos="fade-left">
                            <h4>Hvilke data kan du finde i SMD.</h4>
                            <p>
                                I cvr-registeret findes der mange hunderede tusinde virksomheder, de fleste er dog ikke
                                relevante hvis du er i markedet for for at finde nye kudner

                            </p>
                            <p>
                                Ad vitae recusandae odit possimus. Quaerat cum ipsum corrupti. Odit qui asperiores ea
                                corporis deserunt veritatis quidem expedita perferendis. Qui rerum eligendi ex doloribus
                                quia sit. Porro rerum eum eum.
                            </p>
                        </div>
                    </div>

                    <div className="row about-extra">
                        <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
                            <img src={AboutImage2} className="img-fluid" alt=""/>
                        </div>

                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-right">
                            <h4>Neque saepe temporibus repellat ea ipsum et. Id vel et quia tempora facere
                                reprehenderit.</h4>
                            <p>
                                Delectus alias ut incidunt delectus nam placeat in consequatur. Sed cupiditate quia ea
                                quis. Voluptas nemo qui aut distinctio. Cumque fugit earum est quam officiis numquam.
                                Ducimus corporis autem at blanditiis beatae incidunt sunt.
                            </p>
                            <p>
                                Voluptas saepe natus quidem blanditiis. Non sunt impedit voluptas mollitia beatae. Qui
                                esse molestias. Laudantium libero nisi vitae debitis. Dolorem cupiditate est perferendis
                                iusto.
                            </p>
                            <p>
                                Eum quia in. Magni quas ipsum a. Quis ex voluptatem inventore sint quia modi. Numquam
                                est aut fuga mollitia exercitationem nam accusantium provident quia.
                            </p>
                        </div>

                    </div>

                </div>
            </section>



            <section id="services" className="section-bg" ref={serviceSection}>
                <div className="container" data-aos="fade-up">

                    <header className="section-header">
                        <h3>Priser</h3>
                        <p>Vi tilbyder 2 forskellige abbonnementer. CVR data og CVR data med ekstra kontaktdata </p>
                    </header>

                    <div className="row justify-content-center">

                        <div className="col-md-6 col-lg-5" data-aos="zoom-in" data-aos-delay="100">
                            <div className="box">
                                <div className="icon"><i className="bi bi-briefcase" style={{color: "#ff689b"}}></i></div>
                                <h4 className="title"><a href="src/components/Service">CVR Data</a></h4>
                                <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas
                                    molestias excepturi sint occaecati cupiditate non provident</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5" data-aos="zoom-in" data-aos-delay="200">
                            <div className="box">
                                <div className="icon"><i className="bi bi-card-checklist" style={{color: "#e9bf06"}}></i>
                                </div>
                                <h4 className="title"><a href="src/components/Service">CVR data med kontakt data</a></h4>
                                <p className="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi
                                    ut aliquip ex ea commodo consequat tarad limino ata</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-5" data-aos="zoom-in" data-aos-delay="100">
                            <div className="box">
                                <div className="icon"><i className="bi bi-bar-chart" style={{color: "#3fcdc7"}}></i></div>
                                <h4 className="title"><a href="src/components/Service">DKK 250 pr måned</a></h4>
                                <p className="description">Pakken består af...</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5" data-aos="zoom-in" data-aos-delay="200">
                            <div className="box">
                                <div className="icon"><i className="bi bi-binoculars" style={{color: "#41cf2e"}}></i></div>
                                <h4 className="title"><a href="src/components/Service">DKK 495 pr måned</a></h4>
                                <p className="description">Pakken består af ....</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-5" data-aos="zoom-in" data-aos-delay="100">
                            <div className="box">
                                <div className="icon"><i className="bi bi-brightness-high" style={{color: "#d6ff22"}}></i>
                                </div>
                                <h4 className="title"><a href="src/components/Service">500 downloads pr måned </a></h4>
                                <p className="description"></p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5" data-aos="zoom-in" data-aos-delay="200">
                            <div className="box">
                                <div className="icon"><i className=" bi bi-calendar4-week" style={{color: "#4680ff"}}></i>
                                </div>
                                <h4 className="title"><a href="src/components/Service">100 kontakt personer pr måned</a></h4>
                                <p className="description"></p>
                            </div>
                        </div>

                        <a onClick={handleCreateUserSection} style={{ cursor: "pointer" }} className="btn-get-started scrollto">Create a user</a>

                    </div>


                </div>


            </section>


            <WhyUs/>


            <section id="team" ref={demoSection}>
                <div className="container" data-aos="fade-up">
                    <div className="section-header">
                        <h3>Book a demo</h3>

                    </div>


                    <div className="calendly-inline-widget" data-url="https://calendly.com/sabroe/15min"
                         style={{minWidth: "320px", height: "700px"}}></div>


                </div>


            </section>

            <section id="createUser" className="mt-5 mb-5" ref={createUserSection}>

                <stripe-pricing-table pricing-table-id="prctbl_1PmXdN2LvaKHULYKaQNuoZAk"
                                      publishable-key="pk_test_51Ow90A2LvaKHULYKy3bTAFhIUlKijbTlfMUZ6LAhQWYFfzuPozc7Evzdz7qh8QpCpxNXzE8mtPEGtm4349XvGPlC000Xdhgxh1">
                </stripe-pricing-table>


            </section>


            <Contact/>

        </main>

        <Footer/>

        <div className="modal fade show" id="exampleModal" tabIndex="-1"  style={{ display: showModal }} role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Company data</h5>
                    </div>
                    <div className="modal-body">

                        <div className="row">
                            <div className="col-md-4">
                                <b>Name</b>
                            </div>
                            <div className="col-md-8">
                                {company?.name}
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-4">
                                <b>Cvr number</b>
                            </div>
                            <div className="col-md-8">
                                {company?.cvr_number}
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-4">
                                <b>Road</b>
                            </div>
                            <div className="col-md-8">
                                {company?.road}
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-4">
                                <b>Postal number</b>
                            </div>
                            <div className="col-md-8">
                                {company?.postal_number}
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-4">
                                <b>Company type</b>
                            </div>
                            <div className="col-md-8">
                                {company?.business_form_long_description}
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleCloseCompanyData}>Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div className={modalOpenClass}></div>


    </>)
}

export default Home;